/* VideoBackground.css */
.video-background-container {
    position: relative;
    width: 100%;
    height: 100vh; /* Full viewport height */
    overflow: hidden;
  }
  
  .video-background {
    /*position: fixed; /* Use fixed for full viewport coverage */
    top: 0;
    left: 0;
    width: 100%; /* Ensure it covers the full viewport .width */
    height: 100vh; /* Ensure it covers the full viewport height */
    object-fit: cover; /* Maintain aspect ratio while filling */
    z-index: -1; /* Keep video behind other elements */
  }
  
  
  .overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    font-family: 'Arial', sans-serif;
    z-index: 10;
  }

  
  .overlay-text h1 {
    font-size: calc(12vw + 20px);
    font-weight: 500;
    line-height: 1;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  }
  
  .overlay-text p {
    font-size: 1.5rem;
    margin: 0;
  }
  
